<template>
  <div class="container">
    <div class="row">
        <div class="col-12">
            <div class="card card-body">
                <div class="col-12 table-responsive">
                    <table class="table table-bordered table-sm table-hover">
                        <thead>
                            <th>
                                العنوان
                            </th>
                            <th>
                                التاريخ
                            </th>
                            <th>
                                النوع
                            </th>
                            <th>
                                الخيارات
                            </th>
                        </thead>
                        <tbody>
                            <tr v-for="report in reports" :key="report._id">
                                <td>
                                    {{ report.title }}
                                </td>
                                <td>
                                    {{ report.date }}
                                </td>
                                <td>
                                    <span v-if="report.type == 'teachers-lessons'">تقرير تحضير الدروس</span>
                                </td>
                                <td>
                                    <a :href="'/reports/teachers-lessons?school_id=' + school_id + '&report_id=' + report._id" v-if="report.type == 'teachers-lessons'" class="btn btn-primary btn-sm">
                                        <i class="fa fa-eye"></i>
                                        عرض التقرير
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            school_id: localStorage.getItem("school_id"),
            reports: []
        }
    },
    created(){
        var g = this;
        $.post(api + '/api/reports/all', {
            school_id: g.school_id
        }).then(function(r){
            g.reports = r.response
        }).catch(function(){
            alert("حدث خطأ")
        })
    }
}
</script>

<style>

</style>